/** AOS Initialization */

'use strict'

AOS.init({
  disable: $(window).width() < 992,
  offset: 160,
  duration: 400,
  easing: 'ease-out',
  once: true
})